import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Checkbox from 'components/Checkbox';

import * as S from './styles';

type Props = {
  text: React.ReactNode;
  okText?: string;
  cancelText?: string;
  onOk?: () => any | Promise<any>;
  onCancel?: () => void;
  visible?: boolean;
  dontShowAgainKey?: string;
  cancelAfterOk?: boolean;
  hideOk?: boolean;
};

const WRAP_PROPS = { 'data-testid': 'warningModal' };

const WarningModal: React.FC<Props> = ({
  text,
  visible,
  okText,
  cancelText,
  onCancel,
  onOk,
  dontShowAgainKey,
  cancelAfterOk,
  hideOk,
}) => {
  const [okLoading, setOkLoading] = useState(false);
  const [internalVisible, setInternalVisible] = useState<boolean | undefined>(false);
  const dontShowAgainRef = useRef<boolean>(false);

  useEffect(() => {
    const localStorage = window.localStorage;
    if (visible && dontShowAgainKey && localStorage && localStorage.getItem(dontShowAgainKey)) {
      onOk?.();
      if (cancelAfterOk) {
        onCancel?.();
      }
      return;
    }

    setInternalVisible(visible);
  }, [visible, dontShowAgainKey, onOk, cancelAfterOk, onCancel]);

  const handleOk = useCallback(async () => {
    if (dontShowAgainKey && dontShowAgainRef.current) {
      localStorage.setItem(dontShowAgainKey, 'true');
    }
    const result = onOk?.();
    setOkLoading(true);
    await result;
    setOkLoading(false);

    if (cancelAfterOk) {
      onCancel?.();
    }
  }, [dontShowAgainKey, onOk, setOkLoading, cancelAfterOk, onCancel]);

  const handleDontShowAgainChange = useCallback(({ target }: CheckboxChangeEvent) => {
    dontShowAgainRef.current = target.checked;
  }, []);

  return (
    <S.Wrap visible={internalVisible} onCancel={onCancel} footer={null} wrapProps={WRAP_PROPS}>
      <S.Body>
        <S.WarningIcon />
        <S.Text>{text}</S.Text>
      </S.Body>
      <S.Footer>
        <S.NoButton onClick={onCancel} data-testid="warningModal-cancel">
          {cancelText || 'No'}
        </S.NoButton>
        {!hideOk && (
          <S.YesButton
            loading={okLoading}
            type="primary"
            onClick={handleOk}
            data-testid="warningModal-ok"
          >
            {okText || 'Yes'}
          </S.YesButton>
        )}
      </S.Footer>
      {dontShowAgainKey && (
        <S.DontShowAgainWrap>
          <Checkbox onChange={handleDontShowAgainChange}>Don’t show this window again</Checkbox>
        </S.DontShowAgainWrap>
      )}
    </S.Wrap>
  );
};

export default WarningModal;
