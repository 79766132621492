import React, { useState, createContext, useCallback, useMemo } from 'react';

import WarningModal from 'components/Modals/WarningModal';

type FirebaseAuthProviderType = React.FC & { Context: typeof WarningContext };

type WarningPropsValueType = Omit<ComponentProps<typeof WarningModal>, 'visible'>;

const WarningContext = createContext<{
  openWarning: (props: WarningPropsValueType) => void;
  closeWarning: () => void;
}>({
  openWarning: () => {},
  closeWarning: () => {},
});

const WarningContextProvider: FirebaseAuthProviderType = ({ children }) => {
  const [warningProps, setWarningProps] = useState<
    WarningPropsValueType & { text: React.ReactNode }
  >();

  const closeWarning = useCallback(() => setWarningProps(undefined), [setWarningProps]);

  const openWarning = useCallback(
    (props: WarningPropsValueType) => {
      if (!props.onCancel) {
        props.onCancel = closeWarning;
      }
      setWarningProps(props);
    },
    [closeWarning, setWarningProps]
  );

  return (
    <WarningContext.Provider
      value={useMemo(
        () => ({
          openWarning,
          closeWarning,
        }),
        [openWarning, closeWarning]
      )}
    >
      {children}
      {warningProps && <WarningModal {...warningProps} visible />}
    </WarningContext.Provider>
  );
};

WarningContextProvider.Context = WarningContext;

export default WarningContextProvider;
